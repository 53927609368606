/* eslint-disable no-console */
import { countries } from 'countries-list';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { enumOptionsSelector, featureFlagsSelector, taxonomyTermOptionsNoFirstLevelSelector } from '../../../../../state/constants/selectors';
import { FormFieldRadioWrapper, FormFieldSearchWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepThree.module.scss';

const occupationOptionsSelector = state => enumOptionsSelector(state, 'occupation_type');
const countryOptionsSelector = state => enumOptionsSelector(state, 'country_type');
const ethnicityOptionsSelector = state => taxonomyTermOptionsNoFirstLevelSelector(state, 'ethnicity');

const RegisterAuditorFormStepThree = () => {
  const { values } = useFormikContext();
  const occupationOptions = useSelector(occupationOptionsSelector);
  const ethnicityOptions = useSelector(ethnicityOptionsSelector);
  const countryOptions = useSelector(countryOptionsSelector);
  const { PPSNumberOnAuditorApproval } = useSelector(featureFlagsSelector);
  const allCountries = Object.keys(countries)
    .map(item => ({ ...countries[item], iso: item }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const nameCountryOptions = allCountries.map(item => ({
    text: `${item.name} ${item.emoji}`,
    value: item.iso,
  }));

  return (
    <FormLayout subtitle="2 of 3" title="Basics details">
      <span className={styles.titleQuestion}>
        {'What\'s your occupation?'}
      </span>
      <FormFieldSelectWrapper
        required
        name="occupation"
        options={occupationOptions}
        placeholder="Select an occupation"
      />
      <span className={styles.titleQuestion}>
        {'What\'s your nationality?'}
      </span>
      <FormFieldSearchWrapper
        required
        name="nationality"
        options={nameCountryOptions}
        placeholder="Select a Country"
      />
      <>
        <span className={styles.titleQuestion}>
          {'What\'s your ethnicity?'}
        </span>
        <FormFieldSelectWrapper
          required
          name="ethnicity"
          options={ethnicityOptions}
          placeholder="Select an ethnicity"
        />
      </>
      <span className={styles.titleQuestion}>
        {'Where do you live?'}
      </span>
      {countryOptions.map(country => (
        <div key={country.value} className={styles.radio}>
          <FormFieldRadioWrapper
            labelAfterRadio
            label={country.text}
            name="country"
            value={country.value}
          />
        </div>
      ))}
      {
        PPSNumberOnAuditorApproval ? null : (
          <>
            <span className={styles.titleQuestion}>
              {values.country === undefined
                ? 'National Insurance Number or PPS Number' : values.country === 'Ireland' ? 'PPS Number' : 'National Insurance Number'}
            </span>
            <FormFieldWrapper
              name="ni_pps"
              placeholder="Enter the number"
            />
          </>)
      }
    </FormLayout>
  );
};

export default RegisterAuditorFormStepThree;
