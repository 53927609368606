import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Country, UserRole } from '../../../../../constants';
import getFieldAuditorStatus from '../../../../../fieldLogic/auditor';
import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import { enumOptionsSelector, featureFlagsSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldSelectWrapper,
  FormFieldWrapper,
} from '../../../../common';

const countryOptionsSelector = state => enumOptionsSelector(state, 'country_type');

const circumstancesOptionsSelector = state => enumOptionsSelector(state, 'circumstances_type');
const studentLoansOptionsSelector = state => enumOptionsSelector(state, 'student_loans_type');

const EditBankInfoTab = ({ role, mode }) => {
  const { values, setFieldValue } = useFormikContext();
  const bankCountryName = 'bank_country';
  const [bankCountryField] = useField(bankCountryName);
  const { PPSNumberOnAuditorApproval } = useSelector(featureFlagsSelector);

  const countryOptions = useSelector(countryOptionsSelector);
  const circumstancesOptions = useSelector(circumstancesOptionsSelector);
  const studentLoansOptions = useSelector(studentLoansOptionsSelector);
  const [niPpsNumber, setNiPpsNumber] = useState(undefined);

  useEffect(() => {
    if (bankCountryField.value === Country.GBP) {
      setFieldValue('swift_code', '', true);
      setFieldValue('iban_number', '', true);
    }
    if (bankCountryField.value === Country.IRL) {
      setFieldValue('account_number', '', true);
      setFieldValue('sort_code', '', true);
    }
  }, [setFieldValue, bankCountryField.value]);

  useEffect(() => {
    setNiPpsNumber(values.ni_pps);
  }, []);

  return (
    <>
      <FormFieldWrapper
        inline
        label="Name on Card / Bank Account Holder"
        name="card_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="Bank Country"
        name={bankCountryName}
        options={countryOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      {bankCountryField.value === Country.GBP && (
        <>
          <FormFieldWrapper
            inline
            label="Bank Account Number"
            name="account_number"
            onStateHandler={getFieldAuditorStatus(role, mode)}
          />
          <FormFieldWrapper
            inline
            label="Bank Sort Code"
            name="sort_code"
            onStateHandler={getFieldAuditorStatus(role, mode)}
          />
        </>
      )}

      {bankCountryField.value === Country.IRL && (
        <>
          <FormFieldWrapper
            inline
            label="Swift Code"
            name="swift_code"
            onStateHandler={getFieldAuditorStatus(role, mode)}
          />
          <FormFieldWrapper
            inline
            label="IBAN Number"
            name="iban_number"
            onStateHandler={getFieldAuditorStatus(role, mode)}
          />
        </>
      )}

      <FormFieldSelectWrapper
        inline
        label="What circumstances of work you have?"
        name="circumstances"
        options={circumstancesOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="What kind of student loan do you have?"
        name="student_loans"
        options={studentLoansOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      {
        PPSNumberOnAuditorApproval ? (
          <>
            <FormFieldWrapper
              inline
              label="NI or PPS Number"
              name="ni_pps"
              onStateHandler={ role === UserRole.AUDITOR && !niPpsNumber
                ? () => FieldStatus.EDITABLE : getFieldAuditorStatus(role, mode)}
            />
          </>) : null
      }
    </>
  );
};

EditBankInfoTab.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default EditBankInfoTab;
