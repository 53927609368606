import { ColumnsLinkFormat } from '../../../../constants';
import { AuditFields } from '../../../../rolePermissionMatrix/entity/audits';
import { getRoleLinkFormat } from '../../../../utils/getRoleLinkFormat';
import {
  SLTableCellDateFormatter,
  SLTableCellDateFormatterType,
  SLTableCellEnumFormatter,
  SLTableCellFormatter,
} from '../../../common';
import SLTableCellDateFormatterWithUrgency
  from '../../../common/SLTableCellFormatters/SLTableCellDateWithUrgencyFormatter.component';
import {
  AuditIdCellFormatter,
  AuditorNameCellFormatter,
  CellSumReclaimableExpensesFormatter,
  CurrencyFormatter,
  StartAuditButtonCellFormatter,
  SurveyResultCellFormatter } from './CellFormatters';
import PaysCellFormatter from './CellFormatters/PaysCellFormatter.component';
import styles from './CellFormatters/styles.module.scss';

export const nonAuditorColumns = ({ scheduleTypeOptions, itemToOrderOptions, role }) => ([
  {
    accessor: AuditFields.internalId,
    label: 'Audit ID',
    Cell: AuditIdCellFormatter,
  },
  {
    accessor: AuditFields.orderInternalId,
    label: 'Order ID',
    Cell: SLTableCellFormatter({
      linkFormat: getRoleLinkFormat(role, ColumnsLinkFormat.ORDER_ID),
      textFormat: '#order_internal_id',
    }),
  },
  {
    accessor: AuditFields.siteCode,
    label: 'Site Code',
  },
  {
    accessor: AuditFields.siteName,
    label: 'Premise Name',
    Cell: SLTableCellFormatter({
      linkFormat: getRoleLinkFormat(role, ColumnsLinkFormat.SITE_ID),
      textFormat: '#site_name',
    }),
  },
  {
    accessor: AuditFields.sitePostCode,
    label: 'Post Code',
  },
  {
    accessor: AuditFields.clientName,
    label: 'Client',
    Cell: SLTableCellFormatter({
      linkFormat: getRoleLinkFormat(role, ColumnsLinkFormat.CLIENT_ID),
      textFormat: '#client_name',
    }),
  },
  {
    accessor: AuditFields.auditorName,
    label: 'Auditor',
    Cell: AuditorNameCellFormatter,
  },
  {
    accessor: AuditFields.orderScheduleType,
    label: 'Schedule Type',
    Cell: SLTableCellEnumFormatter({ enumObject: scheduleTypeOptions }),
  },
  {
    accessor: AuditFields.itemToOrder,
    label: 'Item To Order',
    Cell: SLTableCellEnumFormatter({ enumObject: itemToOrderOptions }),
  },
  {
    accessor: AuditFields.visitInfo,
    label: 'Info',
  },
  {
    accessor: AuditFields.deadlineDate,
    label: 'Visit Deadline',
    Cell: SLTableCellDateFormatter({
      type: SLTableCellDateFormatterType.CUSTOM, classNames: [styles.bold, styles.secondary],
    }),
  },
  {
    accessor: AuditFields.orderStartDate,
    label: 'Order Start Date',
    Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
  },
  {
    accessor: AuditFields.orderEndDate,
    label: 'Order Deadline',
    Cell: SLTableCellDateFormatterWithUrgency({ type: SLTableCellDateFormatterType.CUSTOM }),
  },
  {
    accessor: AuditFields.dateOfVisit,
    label: 'Visit Date',
    Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
  },
  {
    accessor: AuditFields.surveyResult,
    label: 'Result',
    Cell: SurveyResultCellFormatter,
    sortable: false,
  },
]);

export const auditorColumns = status => {
  const availableColumns = [
    {
      accessor: 'internal_id',
      label: 'Audit',
    },
    {
      accessor: 'site_name',
      label: 'Site Name',
    },
    {
      accessor: 'client_name',
      label: 'Client Name',
    },
    {
      accessor: 'site_post_code',
      label: 'Location',
    },
    {
      accessor: 'mileage',
      label: 'Mileage',
      sortable: false,
    },
    {
      accessor: 'estimated_expenses',
      label: 'Estimated Expenses',
      sortable: false,
    },
    {
      accessor: 'auditor_pay_per_audit',
      label: 'Pay',
      Cell: CurrencyFormatter,
      sortable: false,
    },
    {
      accessor: 'holiday_pay',
      label: 'Holiday Pay',
      Cell: CurrencyFormatter,
      sortable: false,
    },
    {
      accessor: 'total_pay',
      label: 'Total Pay',
      Cell: CurrencyFormatter,
      sortable: false,
    },
    {
      accessor: 'holiday_pay_assigned',
      label: 'Holiday Pay',
      Cell: cell => PaysCellFormatter({ cell, type: 'holiday_pay' }),
      sortable: false,
    },
    {
      accessor: 'total_pay_assigned',
      label: 'Total Pay',
      Cell: cell => PaysCellFormatter({ cell, type: 'total_pay' }),
      sortable: false,
    },
    {
      accessor: 'deadline_date',
      label: 'Visit Deadline',
      Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
    },
    {
      accessor: 'date_of_visit',
      label: 'Visit Date',
      Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
      sortable: true,
    },
    {
      accessor: 'submitted_date',
      label: 'Date completed',
      Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
      sortable: true,
    },
    {
      accessor: 'entrance_charge',
      label: 'Entrance Charge',
      sortable: false,
    },
    {
      accessor: 'travel_expenses',
      label: 'Travel Expenses',
      sortable: false,
    },
    {
      accessor: 'reclaimable_expenses',
      label: 'Reclaimable Expenses',
      Cell: cell => CellSumReclaimableExpensesFormatter(cell),
      sortable: false,
    },
    {
      accessor: 'pay_date',
      label: 'Paid Date',
      Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
      sortable: true,
    },
    {
      accessor: 'status',
      label: 'Status',
      sortable: false,
    },
    {
      accessor: 'id',
      label: '',
      Cell: StartAuditButtonCellFormatter,
      sortable: false,
    },
  ];

  const assignedColumns = [
    'internal_id',
    'client_name',
    'site_name',
    'site_post_code',
    'mileage',
    'estimated_expenses',
    'auditor_pay_per_audit',
    'deadline_date',
    'holiday_pay_assigned',
    'total_pay_assigned',
    'id',
    'offline_download',
  ];

  const submittedColumns = [
    'internal_id',
    'client_name',
    'site_post_code',
    'date_of_visit',
    'auditor_pay_per_audit',
    'holiday_pay',
    'total_pay',
    'mileage',
    'submitted_date',
    'entrance_charge',
    'travel_expenses',
    'reclaimable_expenses',
    'pay_date',
    'status',
    'tokens',
  ];

  if (status === 'assigned') {
    return availableColumns.filter(column => assignedColumns.includes(column.accessor));
  }

  return availableColumns.filter(column => submittedColumns.includes(column.accessor));
};
